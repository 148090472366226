<template>
  <h1>This is Review page. Developing……</h1>
</template>

<style></style>

<script>
export default {
  inject: ["globalVariable"],
};
</script>
